import React,{useState,useEffect} from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import LoginPage from './pages/Login';
import RegisterPage from './pages/Register';
import RegisterPage2 from './pages/Register2';
import Dashboard from './pages/Dashboard';
import UserContext from './contexts/UserContext';
import DataContext from './contexts/DataContext';
import PrivateRoute from './components/PrivateRoute';
import Header from './components/Navbar';
import Branches from './pages/Branches';
import Departments from './pages/Departments';
import Employees from './pages/Employees';

import jwt_decode from "jwt-decode";

function App() {
  const [isLogged, setIsLogged] = useState(false);
  const [data, setData] = useState({});

  const dataValue = {
    data,
    setData,
  }

  const userData = {
    isLogged,
    setIsLogged,
  }


  useEffect(() => {
    const checkToken = async() => {
      try {
        const token = localStorage.getItem("token") || null;
        console.log(token)
        if (token !== null) {
          const decoded = jwt_decode(token);
          const expDate = new Date(decoded.exp * 1000);
          const now = new Date();
          const secondsDiff = now.getTime() - expDate.getTime();
          if (secondsDiff > 0) {
            setIsLogged(true);
          }
        } else {
          setIsLogged(false);
          localStorage.removeItem("token");
        }
      } catch (error) {
        console.log(error);
      }
    }
    checkToken();
  }, []);

  return (
    <DataContext.Provider value={dataValue}>
      <UserContext.Provider value={userData}>
        <div className="App h-100">
          <Router>
          {isLogged && <Header />}
            <Switch>
              <Route exact path="/" component={LoginPage} />
              <Route path="/login" component={LoginPage} />
              <Route path="/register" component={RegisterPage} />
              <Route path="/register2" component={RegisterPage2} />
              <PrivateRoute path="/dashboard">
                <Dashboard />
              </PrivateRoute>
              <PrivateRoute path="/branches">
                <Branches />
              </PrivateRoute>
              <PrivateRoute path="/departments">
                <Departments />
              </PrivateRoute>
              <PrivateRoute path="/employees">
                <Employees />
              </PrivateRoute>
            </Switch>
          </Router>
        </div>
      </UserContext.Provider>
    </DataContext.Provider>
  );
}

export default App;
