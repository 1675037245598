import React,{useState,useContext,useEffect} from 'react';
import UserContext from '../contexts/UserContext';
import {
    Route,
    Redirect,
  } from "react-router-dom";
import jwt_decode from "jwt-decode";

function PrivateRoute({ children, ...rest }) {

    const {isLogged} = useContext(UserContext);

    return (
      <Route
        {...rest}
        render={() =>
          isLogged ? (
            children
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    );
  }

export default PrivateRoute;