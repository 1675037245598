import React, { useState, useEffect, useContext } from 'react'
import { Card, Table, Badge, Button, Spinner,Modal,Form,InputGroup,Alert,Image } from 'react-bootstrap';
import { toast } from 'react-toastify';
import DataContext from '../contexts/DataContext';
import { useHistory } from "react-router-dom";
import DataTable from 'react-data-table-component';

function EmployeesList(props) {
    const history = useHistory();

    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(false);
    const {data} = useContext(DataContext);
    const [employeeId, setEmployeeId] = useState(null);
    const [userName, setUserName] = useState();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [tcNo, setTcNo] = useState(null);
    const [tel, setTel] = useState('');
    const [locationId, setLocationId] = useState('');
    const [position, setPosition] = useState('');
    const [departmentId, setDepartmentId] = useState('');
    const [password, setPassword] = useState('');
    const [passwordAgain, setPasswordAgain] = useState('');
    const [image , setImage] = useState(null);
    const [getUserLoading, setGetUserLoading] = useState(false);

    const [show, setShow] = useState(false);
    const [showPasswordModal, setShowPasswordModal] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = (id) => {
        setEmployeeId(id);
        getUserById(id);
        setShow(true);
    }

    const handleClosePasswordModal = () => {
        setShowPasswordModal(false);
        setPassword('');
        setPasswordAgain('');
    };

    const handleShowPasswordModal = (id) => {
        setEmployeeId(id);
        setShowPasswordModal(true);
    }

    const getEmployees = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem("token");
            await fetch('https://s1.digilirapay.com/assistant/company/getusers', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                    'Access-Control-Allow-Origin': '*'
                },
            })
            .then(response => response.json())
            .then(res => {
                setLoading(false);
                if (res.message) throw new Error(res.message);
                setEmployees(res);
            })
        } catch (error) {
            toast.error(error.message, {
                theme: "colored",
                position: toast.POSITION.TOP_CENTER
            });
            setLoading(false);
        }
    }

    const getUserById = async (id) => {
        try {
            setGetUserLoading(true);
            const token = localStorage.getItem("token");
            await fetch('https://s1.digilirapay.com/assistant/company/getuser/'+id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                    'Access-Control-Allow-Origin': '*'
                },
            })
            .then(response => response.json())
            .then(res => {
                console.log(res)
                setFirstName(res.firstName);
                setLastName(res.lastName);
                setTcNo(res.tcno);
                setTel(res.tel);
                setLocationId(res.office);
                setPosition(res.position);
                setDepartmentId(res.department);
                setUserName(res.username);
                setGetUserLoading(false);
                setImage(res.image ? res.image : null);
            })
        } catch (error) {
            toast.error(error.message, {
                theme: "colored",
                position: toast.POSITION.TOP_CENTER
            });
            setGetUserLoading(false);
        }
    }

    useEffect(() => {
        getEmployees();
    },[]);

    useEffect(() => {
        getEmployees();
    },[props.loading]);

    const officeName = (id) => {
        const list = data.locations;
        const location = list.find(item => item._id === id);
        return location ? location.name : "-";
    }

    const departmentName = (id) => {
        const list = data.department;
        const department = list.find(item => item._id === id);
        return department ? department.name : "-";
    }

    const columns = [
        {
            name: 'Adı',
            selector: 'firstName',
            sortable: true,
            cell: row => <div>{row.firstName ? row.firstName : "-"}</div>
        },
        {
            name: 'Soyadı',
            selector: 'lastName',
            sortable: true,
            cell: row => <div>{row.lastName ? row.lastName : "-"}</div>
        },
        {
            name: 'Lokasyon',
            selector: 'office',
            sortable: true,
            cell: row => <div>{officeName(row.office)}</div>
        },
        {
            name: 'Departman',
            selector: 'department',
            sortable: true,
            cell: row => <div>{departmentName(row.department)}</div>
        },
        {
            name: 'Actions',
            sortable: true,
            cell: row => <div>
                <Button variant="outline-warning" size="sm" onClick={() => handleShowPasswordModal(row._id)} title="Şifre Güncelle">
                    <i className="fa fa-key"></i>
                </Button>
                {' '}
                <Button variant="outline-dark" size="sm" onClick={() => handleShow(row._id)}>
                    <i className="fa fa-pencil-square-o"></i>
                </Button>
                {' '}
                <Button variant="outline-danger" size="sm" onClick={() => deleteEmployee(row._id)}>
                    <i className="fa fa-trash"></i>
                </Button>
            </div>
        },
    ];


    const hSubmit = async (e) => {
        e.preventDefault();
        try {

            if (firstName === null || firstName === '') throw new Error("Lütfen personel adını giriniz!");
            if (lastName === null || lastName === '') throw new Error("Lütfen personel soyadını giriniz!");
            if (userName === null || userName === '') throw new Error("Lütfen kullanıcı adını giriniz!");
            if (tcNo === null || tcNo === '') throw new Error("Lütfen TC kimlik numarasını giriniz!");

            if (tel === null || tel === '') throw new Error("Lütfen telefon numarasını giriniz!");
            if (locationId === null || locationId === '') throw new Error("Lütfen lokasyon seçiniz!");
            if (position === null || position === '') throw new Error("Lütfen ünvan giriniz!");

            const token = localStorage.getItem("token");
            setLoading(true);
            await fetch('https://s1.digilirapay.com/assistant/company/updateuser', {
                method: 'POST',
                
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                    'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify({user: employeeId,firstName: firstName, lastName: lastName, username: userName, tcno: tcNo, tel: tel, office: locationId, position: position,department:departmentId,company: data._id})
            })
            .then(response => response.json())
            .then(res => {
                console.log(res);
                if (res.message) throw new Error(res.message);
                toast.success("Personel başarıyla eklendi!", {
                    theme: "colored",
                    position: toast.POSITION.TOP_CENTER
                });
                getEmployees();
                setLoading(false);
                handleClose();
            })
        } catch (error) {
            setLoading(false);
            toast.error(error.message, {
                theme: "colored",
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    const hSubmitPassword = async (e) => {
        e.preventDefault();
        try {
            if (password === null || password === '') throw new Error("Lütfen şifreyi giriniz!");
            if (passwordAgain === null || passwordAgain === '') throw new Error("Lütfen şifreyi tekrar giriniz!");
            if (password !== passwordAgain) throw new Error("Şifreler uyuşmuyor!");

            const token = localStorage.getItem("token");

            setLoading(true);

            await fetch('https://s1.digilirapay.com/assistant/company/updateuser', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                    'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify({user: employeeId, password: password})
            })
            .then(response => response.json())
            .then(res => {
                if (res.message) throw new Error(res.message);
                toast.success("Şifre başarıyla değiştirildi!", {
                    theme: "colored",
                    position: toast.POSITION.TOP_CENTER
                });
                getEmployees();
                setLoading(false);
                handleShowPasswordModal();
            })
        } catch (error) {
            setLoading(false);
            toast.error(error.message, {
                theme: "colored",
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    const handleTcNo = (e) => {
        setTcNo(e.target.value);
    }

    const handleLocationChange = (e) => {
        setLocationId(e.target.value);
    }

    const handleDepartmentChange = (e) => {
        setDepartmentId(e.target.value);
    }

    const departmentsInLocation = (id) => {
        const departments = data.department.filter(item => item.location === locationId);
        return departments;
    }

    const deleteEmployee = async (id) => {
        try {
            if (!window.confirm("Çalışanı silmek istediğinize emin misiniz?")) { return; }
            setLoading(true);
            const token = localStorage.getItem("token");
            await fetch('https://s1.digilirapay.com/assistant/company/updateuser/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                    'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify({user: id, status: -1})
            })
            .then(response => response.json())
            .then(res => {
                if (res.message) throw new Error(res.message);
                toast.success("Personel başarıyla silindi!", {
                    theme: "colored",
                    position: toast.POSITION.TOP_CENTER
                });
                getEmployees();
                setLoading(false);
            })
        } catch (error) {
            setLoading(false);
            toast.error(error.message, {
                theme: "colored",
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    const handleImageUpload = (event) => {
        try {
            const file = event.target.files[0];
            const maxSize = 1 * 1024 * 1024; // 1 MB in bytes
            setLoading(true)
            if (file.size > maxSize) {
              throw new Error("Dosya boyutu 1 MB'dan büyük olamaz!");
            }

            //check file extensions 
            const validFileExtensions = [".jpg", ".jpeg",".gif", ".png"];
            const fileName = file.name;
            const fileNameExt = fileName.substr(fileName.lastIndexOf('.'));
            if (validFileExtensions.indexOf(fileNameExt) === -1) {
                throw new Error("Dosya uzantısı desteklenmiyor!");
            }

            const reader = new FileReader();
            reader.onload = () => {
              const base64Data = reader.result;
              setImage(base64Data)
                setLoading(false)
            };
            reader.readAsDataURL(file);
        } catch (error) {
            setLoading(false)
            toast.error(error.message, {
                theme: "colored",
                position: toast.POSITION.TOP_CENTER
            });
        }
    };

    const handleBufferImage = (bufferData) => {
        //convert buffer to base64
        const base64Flag = "data:image/jpeg;base64,";

        return base64Flag + bufferData;
    };

    return (
        <>
        <Card className="loginCard">
        <Card.Body>
            <Card.Title className="mb-2">Çalışanlar</Card.Title>
            <div className="subTitle mb-3">Personel Listesi.</div>
            {loading ? (
                <div className="text-center">
                    <Spinner animation="border" variant="dark" />
                </div>
            ) : (
                <DataTable
                columns={columns}
                data={employees}
                pagination
                paginationPerPage={5}
                paginationRowsPerPageOptions={[5,10,15]}
                paginationComponentOptions={{
                    rowsPerPageText: 'Sayfa başına',
                    rangeSeparatorText: ' / ',
                    noRowsPerPage: false,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: 'Tümünü Seç',
                }}
                
                noDataComponent="Henüz çalışan eklememişsiniz."
            />
            )}
        </Card.Body>
    </Card>
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        {getUserLoading ? (<Spinner animation="border" variant="dark" />) : (<Modal.Title style={{fontSize: '16px',fontWeight: '700'}}>Çalışan Bilgilerini Güncelle</Modal.Title>)}
        </Modal.Header>
        {!getUserLoading && (
        <Modal.Body>
            <div className="employeeAvatar mb-3" style={{display: 'none'}}>
                <Image src={image !== null ? handleBufferImage(image) : 'https://placehold.co/100x100'} rounded style={{width: '100px',height: '100px'}}/>
            </div>
            {image}
            <Form onSubmit={hSubmit}>
                <InputGroup className="mb-3" style={{display: 'none'}}>
                    <InputGroup.Text>
                        <i className="fa fa-user"></i>
                    </InputGroup.Text>
                <Form.Control type="file" onChange={handleImageUpload}/>
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text>
                        <i className="fa fa-user"></i>
                    </InputGroup.Text>
                        <Form.Control type="text" value={firstName} onChange={e => setFirstName(e.target.value)} placeholder="Personel Adı" required />
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text>
                        <i className="fa fa-user"></i>
                    </InputGroup.Text>
                        <Form.Control type="text" value={lastName} onChange={e => setLastName(e.target.value)} placeholder="Personel Soyadı" required />
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text>
                        <i className="fa fa-info-circle"></i>
                    </InputGroup.Text>
                        <Form.Control type="text" value={position} onChange={e => setPosition(e.target.value)} placeholder="Ünvan (Örnek: Müdür, Direktör vs)" />
                </InputGroup>
                <InputGroup className="mb-1">
                    <InputGroup.Text>
                        <i className="fa fa-user-plus"></i>
                    </InputGroup.Text>
                        <Form.Control type="text" value={userName} onChange={e => setUserName(e.target.value)} placeholder="Kullanıcı adı giriniz." required />
                </InputGroup>
                <div className="subTitle mb-3">Personel bu kullanıcı adını oturum açarken kullanacaktır.</div>
                <InputGroup className="mb-3">
                    <InputGroup.Text>
                        <i className="fa fa-phone"></i>
                    </InputGroup.Text>
                        <Form.Control type="text" value={tel} onChange={e => setTel(e.target.value)} placeholder="Telefon Numarası" />
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text>
                        <i className="fa fa-id-card"></i>
                    </InputGroup.Text>
                        <Form.Control type="text" value={tcNo} onChange={handleTcNo} placeholder="TC Kimlik Numarası" />
                </InputGroup>
                {data.locations && (
                <InputGroup className="mb-3">
                    <InputGroup.Text>
                        <i className="fa fa-location-arrow"></i>
                    </InputGroup.Text>
                    
                    <Form.Select aria-label="Lokasyon Seçiniz" value={locationId} onChange={handleLocationChange}>
                        <option>Lokasyon Seçiniz</option>
                        {data.locations && data.locations?.map((item, index) => (
                        <option value={item._id} key={index} selected="selected">{item.name}</option>
                        ))}
                    </Form.Select>
                    
                </InputGroup>
                )}

                    <Alert variant="warning" className="mb-1">
                        <p>
                            İşletmeniz için hemen yeni bir departman ekleyin.
                        </p>
                        <hr />
                        <div className="d-flex justify-content-start">
                            <Button onClick={() => history.push('/departments')} variant="warning" size="sm">
                                Departman Ekle
                            </Button>
                        </div>
                    </Alert>

                {data.locations && (
                <InputGroup className="mb-3">
                    <InputGroup.Text>
                        <i className="fa fa-id-card"></i>
                    </InputGroup.Text>
                    <Form.Select aria-label="Departman Seçiniz" value={departmentId} onChange={handleDepartmentChange} disabled={departmentsInLocation(locationId).length === 0 ? true : false}>
                        <option value="">Departmansız</option>
                        {departmentsInLocation(locationId).map((item, index) => (
                        <option value={item._id} key={index}>{item.name}</option>
                        ))}
                    </Form.Select>
                </InputGroup>
                )}
                {data.locations &&  (
                    <Alert variant="light" className="mb-1">
                        <p>
                            Bu lokasyon için hemen yeni bir departman ekleyebilirsiniz.
                        </p>
                        <hr />
                        <div className="d-flex justify-content-start">
                            <Button onClick={() => history.push('/departments')} variant="primary" size="sm">
                                Departman Ekle
                            </Button>
                        </div>
                    </Alert>
                )}
                {!loading ? (
                <div className="d-grid gap-2 mt-3">
                    <Button variant="primary" type="submit">
                        Kaydet
                    </Button>
                </div>
                ) : (
                <div className="d-grid gap-2 mt-3">
                    <Button variant="primary" type="submit" disabled>
                        <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        />
                            Kaydediliyor...
                    </Button>
                </div>
                )} 
            </Form>
        </Modal.Body>
        )}
    </Modal>
        <Modal show={showPasswordModal} onHide={handleClosePasswordModal}>
            <Modal.Header closeButton>
            {getUserLoading ? (<Spinner animation="border" variant="dark" />) : (<Modal.Title style={{fontSize: '16px',fontWeight: '700'}}>Çalışan Şifresini Güncelle</Modal.Title>)}
            </Modal.Header>
            {!getUserLoading && (
            <Modal.Body>
                <Form onSubmit = {hSubmitPassword}>
                    <InputGroup className="mb-3">
                        <InputGroup.Text>
                            <i className="fa fa-key"></i>
                        </InputGroup.Text>
                            <Form.Control type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Yeni Şifre" required />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text>
                            <i className="fa fa-key"></i>
                        </InputGroup.Text>
                            <Form.Control type="password" value={passwordAgain} onChange={e => setPasswordAgain(e.target.value)} placeholder="Yeni Şifre Tekrar" required />
                    </InputGroup>
                    {!loading ? (
                    <div className="d-grid gap-2 mt-3">
                        <Button variant="primary" type="submit">
                            Kaydet
                        </Button>
                    </div>
                    ) : (
                    <div className="d-grid gap-2 mt-3">
                        <Button variant="primary" type="submit" disabled>
                            <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            />
                                Kaydediliyor...
                        </Button>
                    </div>
                    )}
                </Form>
            </Modal.Body>   
            )}
        </Modal>
        </>
    )
}

export default EmployeesList