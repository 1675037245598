import React,{useContext,useEffect,useState} from 'react';
import { Button, Container, Row, Col, Table, Card, Image,Alert,Spinner,Badge } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import SideBar from '../components/SideBar';
import { CircularProgressbar } from 'react-circular-progressbar';
import BranchesBox from '../components/Branches';
import DepartmensBox from '../components/DepartmensBox';
import DataContext from '../contexts/DataContext';
import { toast } from 'react-toastify';
import HomeGetScans from '../components/HomeGetScans';
import DataTable from 'react-data-table-component';

function Dashboard() {
    const history = useHistory();
    const {data} = useContext(DataContext);
    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(false);

    const getEmployees = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem("token");
            await fetch('https://s1.digilirapay.com/assistant/company/getusers', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                    'Access-Control-Allow-Origin': '*'
                },
            })
            .then(response => response.json())
            .then(res => {
                setLoading(false);
                if (res.message) throw new Error(res.message);
                setEmployees(res);
            })
        } catch (error) {
            toast.error(error.message, {
                theme: "colored",
                position: toast.POSITION.TOP_CENTER
            });
            setLoading(false);
        }
    }

    useEffect(() => {
        getEmployees();
    },[]);

    const trimIfLong = (text) => {
        if (text.length > 14) {
            return text.substring(0, 14) + "...";
        } else {
            return text;
        }
    }

    const officeName = (id) => {
        const list = data.locations;
        const location = list.find(item => item._id === id);
        return location ? trimIfLong(location.name) : "-";
    }

    const departmentName = (id) => {
        const list = data.department;
        const department = list.find(item => item._id === id);
        return department ? trimIfLong(department.name) : "-";
    }

    const columns = [
        {
            name: 'Adı',
            selector: row => row.firstName,
            sortable: true,
        },
        {
            name: 'Soyadı',
            selector: row => row.lastName,
            sortable: true,
        },
        {
            name: 'Lokasyon',
            selector: row => officeName(row.office),
            sortable: true,
        },
        {
            name: 'Departman',
            selector: row => departmentName(row.department),
            sortable: true,
        },
    ];
    
    return(
        <Row className="h-100">
            <Col md={2} className="bg-light h-100 loginCard">
                <SideBar />
            </Col>
            <Col md={10}>
                <Container className="p-3" fluid>
                    <Row>
                        {data.locations.length <= 0 &&
                        <Col md={12}>
                            <Alert variant="warning">
                                <Alert.Heading>İlk Adım</Alert.Heading>
                                <p>
                                    İşletmeniz için hemen yeni bir lokasyon ekleyin.
                                </p>
                                <hr />
                                <div className="d-flex justify-content-start">
                                    <Button onClick={() => history.push('/branches')} variant="warning" size="sm">
                                        Şube Ekle
                                    </Button>
                                </div>
                            </Alert>
                        </Col>
                        }
                        <Col md={6}>
                            <Row>
                                <Col md={6}>
                                    <BranchesBox />
                                </Col>
                                <Col md={6}>
                                    <DepartmensBox />
                                </Col>
                                <Col md={12}>
                                    <HomeGetScans />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <Card className="loginCard mb-3">
                                <Card.Body>
                                    <Card.Title className="mb-4">Çalışanlar</Card.Title>
                                    <Row className="mb-2">
                                        <Col xs={9}>
                                            <div className="cardNumber">
                                                {employees.length}
                                            </div>
                                        </Col>
                                        <Col xs={3}>
                                            <div className="d-grid gap-2">
                                                <Button variant="primary" size="sm" onClick={() => history.push('/employees')}>
                                                    Çalışan Ekle
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    {loading ? (
                                        <div className="text-center">
                                            <Spinner animation="border" variant="dark" />
                                        </div>
                                    ) : (
                                        <DataTable
                                            columns={columns}
                                            data={employees}
                                            pagination
                                            paginationPerPage={5}
                                            paginationRowsPerPageOptions={[5,10,15]}
                                            paginationComponentOptions={{
                                                rowsPerPageText: 'Sayfa başına',
                                                rangeSeparatorText: ' / ',
                                                noRowsPerPage: false,
                                                selectAllRowsItem: false,
                                                selectAllRowsItemText: 'Tümünü Seç',
                                            }}
                                            
                                            noDataComponent="Henüz çalışan eklememişsiniz."
                                        />

                                    )} 
                                    {employees.length === 0 && (<Alert variant="light">Henüz çalışan eklememişsiniz.</Alert>)}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
    )
}

export default Dashboard;


