import React,{useState,useContext} from 'react';
import { Container,Row,Col,Card,Form,Button,InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Link,useHistory } from "react-router-dom";
import UserContext from '../contexts/UserContext';
import DataContext from '../contexts/DataContext';
import Logo from '../assets/images/perasyon_wlogo.png';

function LoginPage() {
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const {isLogged,setIsLogged} = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const {setData} = useContext(DataContext);

    function handleMailChange (event) {
        setEmail(event.target.value);
    }

    function handlePasswordChange (event) {
        setPassword(event.target.value);
    }

    async function handleLogin() {
        try {
            setLoading(true);
            //if empty email throw error
            if (email === null) throw new Error("Email adresi zorunludur!");
            //check email is empty
            if (email === "") throw new Error("Email adresi zorunludur!");
            //check email is valid pattern
            if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) throw new Error("Email adresi geçersizdir!");
            //if empty password throw error
            if (password === null) throw new Error("Şifre zorunludur!");
            //check password is empty
            if (password === "") throw new Error("Şifre zorunludur!");

            await fetch('https://s1.digilirapay.com/assistant/company/authenticate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                    },
                    body: JSON.stringify({ username: email, password: password })
                })
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                    setData(data);
                    setLoading(false);
                    if (data.message) throw new Error(data.message);
                    localStorage.setItem("token", data.token);
                    localStorage.removeItem("app_email");
                    toast.success("Giriş başarılı!", {
                        theme: "colored",
                        position: toast.POSITION.TOP_CENTER
                    });
                    setIsLogged(true);
                    history.push('/dashboard');
                })
        } catch (error) {
            setLoading(false);
            toast.error(error.message, {
                theme: "colored",
                position: toast.POSITION.TOP_CENTER
            });
        }
    }


    return (
        <Container fluid className="h-100">
        <Row className="justify-content-center align-items-center h-100">
            <Col md={3}>
            <Card className="loginCard">
                <Card.Body>
                <div className="loginCardTitle mt-3">
                    <img src={Logo} alt="Perasyon Logo" />
                </div>
                <div className="loginCardSubTitle">
                    Hesabınıza erişmek için lütfen giriş yapınız.
                </div>
                <>
                    <InputGroup className="mb-3">
                    <InputGroup.Text>
                        <i className="fa fa-user-circle-o"></i>
                    </InputGroup.Text>
                        <Form.Control type="email" name="app_email" placeholder="isim@email.com" value={email} onChange={handleMailChange}/>
                    </InputGroup>
                    <InputGroup className="mb-2">
                    <InputGroup.Text>
                        <i className="fa fa-lock"></i>
                    </InputGroup.Text>
                        <Form.Control type="password" name="app_password" placeholder="Şifrenizi giriniz." value={password} onChange={handlePasswordChange} />
                    </InputGroup>
                    <Row className="mb-5">
                    <Col xs={6}>
                    </Col>
                    <Col xs={6} className="text-end">
                        <Button variant="link" style={{padding: 0, textDecoration: 'none',color: '#040325'}} className="lostPw" as={Link} to="/">Şifremi Unuttum</Button>
                    </Col>
                    </Row>
                    <div className="d-grid gap-2">
                    {loading ? <Button variant="primary" disabled> <i className="fa fa-spinner fa-spin"></i> Giriş Yapılıyor</Button> : (
                    <Button variant="primary" onClick={handleLogin}>
                        Giriş Yap
                    </Button>
                    )}
                    <div className="or">veya</div>
                    <Button variant="light" as={Link} to="/register">
                        Hemen Kayıt Ol
                    </Button>
                    </div>
                </>
                </Card.Body>
            </Card>
            </Col>
        </Row>
        </Container>
    );
}

export default LoginPage;
