import React,{useState} from 'react';
import { Row,Col,Form,Button,InputGroup,Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Link,useHistory } from "react-router-dom";
import Logo from '../assets/images/perasyon_wlogo.png';

function RegisterPage() {
    const history = useHistory();

    const [email, setEmail] = useState(null);
    const [terms, setTerms] = useState(false);
    const [kvkk, setKvkk] = useState(false);

    const [showTerms, setShowTerms] = useState(false);

    const handleTermsClose = () => setShowTerms(false);
    const handleTermsShow = () => setShowTerms(true);

    const [showKvkk, setShowKvkk] = useState(false);

    const handleKvkkClose = () => setShowKvkk(false);
    const handleKvkkShow = () => setShowKvkk(true);

    async function handleRegister() { 
        try {
            if (email === null) throw new Error("Email adresi zorunludur!");
            //check email is empty
            if (email.target.value === "") throw new Error("Email adresi zorunludur!");
            //check email is valid pattern 
            if (!email.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) throw new Error("Email adresi geçersizdir!");
            //check terms is checked
            if (!terms) throw new Error("Kullanıcı sözleşmesini kabul etmelisiniz!");
            //check kvkk is checked
            if (!kvkk) throw new Error("KVKK sözleşmesini kabul etmelisiniz!");

            //set to localstorage
            localStorage.setItem("app_email", email.target.value);

            toast.info("Kayıt işlemine devam ediniz!", {
                position: toast.POSITION.TOP_CENTER
            });

            history.push('/register2');

        } catch (error) {
            toast.error(error.message, {
                theme: "colored",
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    function handleTermsChange (event) {
        setTerms(event.target.checked);
    }

    function handleKvkkChange (event) {
        setKvkk(event.target.checked);
    }

    return (
        <>
        <Row style={{height: '100%'}} className="no-gap-row">
            <Col md={5} style={{height: '100%'}}>
                <div className="registerCard">
                    <div>
                        <div className="loginCardTitle">
                            <img src={Logo} alt="Perasyon" />
                        </div>
                        <div className="loginCardTitle">
                            Perasyon' a Hoşgeldiniz!
                        </div>
                        <div className="loginCardSubTitle mb-5">
                            Hesap oluşturmak için lütfen e-mail adresinizi giriniz.
                        </div>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>
                            <i className="fa fa-user-circle-o"></i>
                            </InputGroup.Text>
                            <Form.Control type="email" name="app_email" placeholder="isim@email.com" onChange={(e) => setEmail(e)}/>
                        </InputGroup>
                        <div className="mb-2"><Form.Check type="checkbox" label="Kullanıcı Sözleşmesini Okudum, Kabul Ediyorum" value={terms}  onChange={handleTermsChange} onClick={handleTermsShow}/></div>
                        <div className="mb-2"><Form.Check type="checkbox" label="KVKK Aydınlatma Metnini Okudum" value={kvkk}  onChange={handleKvkkChange} onClick={handleKvkkShow} /></div>
                        <div className="d-grid gap-2 mt-4">
                            <Button variant="primary" onClick={handleRegister}>
                                Kaydı Başlat
                            </Button>
                            <div className="or">veya</div>
                            <Button variant="light" as={Link} to="/login">
                                Giriş Yap
                            </Button>
                        </div>
                    </div>
                </div>
            </Col>
            <Col md={7}>
                <div className="registerBgImage"></div>
            </Col>
        </Row>
        <Modal show={showTerms} onHide={handleTermsClose}>
            <Modal.Header closeButton>
                <Modal.Title>Kullanıcı Sözleşmesi</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p style={{fontSize: '0.9rem'}}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nisl quis ultrices aliquam, nunc nisl ultricies nunc, nec aliquam eros nisl eu nu
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleTermsClose} size="sm">
                    Kapat
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal show={showKvkk} onHide={handleKvkkClose}>
            <Modal.Header closeButton>
                <Modal.Title>KVKK Aydınlatma Metni</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p style={{fontSize: '0.9rem'}}>
                Bu Aydınlatma Metni, 6698 Sayılı Kişisel Verilerin Korunması Kanun’unun (“Kanun”) 10 uncu maddesi ile Aydınlatma Yükümlülüğünün Yerine Getirilmesinde Uyulacak Usul ve Esaslar Hakkında Tebliğ kapsamında Veri Sorumlusu sıfatıyla Bayar Teknoloji ve İletişim Anonim Şirketi tarafından hazırlanmıştır. 
                <br/><br/>
                Bayar Teknoloji ve İletişim Anonim Şirketi (“Perasyon” olarak anılacaktır) olarak kişisel verilerin korunmasına büyük önem atfetmektedir. 6698 sayılı Kişisel Verilerin Korunması Kanunu ve ilgili ikincil mevzuat uyarınca “Veri Sorumlusu” sıfatıyla kişisel verilerinizi Kanun’a uygun olarak ve aşağıda belirtilen amaçlar ve sınırlar kapsamında işlemekte  ve buna uygun gerekli idari ve teknik tedbirleri almaya son derece özen gösterilmektedir. Bu sebeple işbu “Kişisel Verilerin İşlenmesi Hakkında Aydınlatma Metni” (“Bildirim”) vasıtasıyla kişisel verilerinizi işleme süreçlerimiz ve ilgili mevzuat kapsamındaki haklarınız hakkında sizleri bilgilendirmek isteriz.
                <br/><br/>
                Hangi Veriler İşlenmektedir?
                <br/><br/>
                Bu başlık altında, çalışan ve üye iş yeri tarafından Perasyon’aa sağlanan ve/veya Perasyon’un kullanımı aşamasında çalışanlar ve üye iş yeri tarafından elde edilen ve 6698 sayılı Kişisel Verilerin Korunması Kanunu (6698 sayılı Kanun) uyarınca kişisel veri sayılan verilerin hangileri olduğu sıralanmıştır. Aksi açıkça belirtilmedikçe, KVKK kapsamında arz edilen hüküm ve koşullar kapsamında “kişisel veri” ifadesi aşağıda yer alan bilgileri ifade edecektir.
                <br/><br/>
                Perasyon, çalışan veya üye iş yeri tarafından hesap oluşturulması esnasında dijital ortamda sağlanan aşağıdaki verileri toplamaktadır.
                <br/><br/>
                Ad, soyad vb. kimlik bilgileri, adres, iş veya özel e-posta adresi, telefon numarası vb. iletişim bilgileri kimlik doğrulama ve hesap erişimi için kullanılan parola ve benzer güvenlik ve işlem bilgileri Perasyon, çalışanlar’ın veya üye iş yerinin web sitesi ve Perasyon uygulaması kullanımı hakkındaki bilgileri teknik bir iletişim dosyası olan çerezleri (Cookie) kullanarak elde edebilmekte olup Perasyon üzerinde sunulmakta olan hizmetlere erişim ve kullanım alışkanlıklarının tespit edilmesi için çerez yoluyla IP bilgileri toplanmaktadır.
                <br/><br/>
                Kanun’un 3 ve 7. maddesi doğrultusunda, geri döndürülemeyecek şekilde anonim hale getirilen veriler, anılan kanun hükümleri uyarınca kişisel veri olarak kabul edilmeyecek olup bu verilere ilişkin işleme faaliyetleri işbu Politika’nın hükümleri ile bağlı olmaksızın gerçekleştirecektir.
                <br/><br/>
                Veriler Hangi Amaçla Kullanılmaktadır?
                <br/><br/>
                Şirketimiz, kişisel verileri aşağıda verilen amaçlarla toplar ve işler.
                <br/><br/>
                -Müşteri, çalışan, ziyaretçi, iş ortağı, tedarikçi ilişkilerini kurabilmek ve yönetebilmek
                <br/>
                – Şirketlerin idari operasyonları yürütebilmek
                <br/>
                – Ürün, uygulama ve hizmetlerimizi geliştirmek
                <br/>
                – Ürün, uygulama ve hizmetlerimizi tanıtmak ve satışını yapabilmek
                <br/>
                – Ticari faaliyetimizi eksiksiz ve aksatmadan sürdürebilmek
                <br/>
                – Tedarikçi, ziyaretçi, çalışan, müşteri portföyümüzü yönetebilmek
                <br/>
                – Her türlü araçla iletişim kurabilmek
                <br/>
                – Reklam, tanıtım ve bilgilendirme faaliyetlerinde bulunmak
                <br/>
                – Site ziyaretçilerimizi analiz etmek ve arşivlemek
                <br/>
                – Uygulama geliştirmek ve yönetmek
                <br/>
                – Veri kaybını önlemek için yedekleme yapmak
                <br/>
                – Şirketimizin, grup şirketlerimizin ve şirketimizle iş ilişkisi içerisinde olan kişilerin hukuki ve ticari güvenliğini sağlamak
                <br/>
                – Şirketimizin ticari ve iş stratejilerinin belirlenmesi ve uygulanmasını sağlamak
                <br/>
                – Web sitelerimiz, uygulamalarımız ve faaliyetimizi sağlamak, güncelleştirmek, sürdürmek ve korumak
                <br/>
                – İlgili mevzuat, yasal süreç veya yönetmeliğin gerektirdiği şekilde sorularınız ve yorumlarınıza yanıt vermek
                <br/>
                – Hizmet hakkında e-posta, anında bildirimler, SMS ve uygulama içi mesajlar dahil ama bunlarla sınırlı olmamak üzere pazarlama ve ödeme iletişimlerini göndermek
                <br/>
                – İstatistik toplamak
                <br/>
                – Faturalandırma, hesap yönetimi ve diğer idari hususlar için
                <br/>
                – Güvenlik sorunları ve istismarı araştırmak ve önlemeye yardımcı olmak için verileri kullanmaktadır.
                <br/><br/>
                Kişisel Veriler Kimlere Hangi Amaçla Aktarılabilir?
                <br/><br/>
                Yukarıda belirttiğimiz amaçlarla, ilgili kişilerin paylaştığı kişisel veriler; grup şirketlerine, tedarikçilere, şirket yetkililerine, çözüm ortaklarına, kanunen yetkili kişi ve kurumlara kanuni yükümlülükleri yerine getirmek amacı ile resmi merci ve kurumlara 6698 Sayılı Kişisel Verilerin Korunması Kanunu’nun 8. ve 9.maddelerinde belirtilen hükümler çerçevesinde aktarılabilecektir. 
                <br/><br/>
                Kişisel Verileri Toplama Yöntemi ve Hukuki Sebebi
                <br/><br/>
                Her türlü sözlü, yazılı ya da elektronik ortamda, yukarıda yer alan amaçlar doğrultusunda, Şirket olarak sunduğumuz ürün ve hizmetlerin yasal çerçevede sunulabilmesi ve bu kapsamda Şirketimizin sözleşme ve yasalardan doğan mesuliyetlerini eksiksiz ve doğru bir şekilde yerine getirebilmesi amacıyla kişisel veriler toplanır. Bu hukuki sebeple toplanan kişisel veriler 6698 Sayılı Kişisel Verilerin Korunması Kanunu’nun 5. ve 6. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları kapsamında işlenebilmekte ve aktarılabilmektedir.
                <br/><br/>
                Şirketimizle paylaşılan kişisel veriler, otomatik ya da otomatik olmayan yöntemlerle yazılı, sözlü ya da elektronik olarak toplanmakta, fiziksel ya da elektronik ortamda veya karma olarak her iki şekilde de saklanabilmektedir. Saklandıkları ortama yetkisiz erişimin engellenmesi, kaybolmaması, çalınmaması ve zarar görmemesi için gerekli olan teknik ve idari tedbirler Şirketimiz tarafından alınmaktadır.
                <br/><br/>
                Kişisel veriler, yasal saklama süreleri boyunca saklanacak, bu süre sona erdiğinde silinme veya anonim hale getirilerek şirketimiz veri tabanından çıkartılacaktır. Yasal saklama süresi belirtilmemiş olan verileriniz işleme amacının gerektirdiği süre boyunca saklanır ve işlenir.
                <br/><br/>
                Kişisel Veri Sahibinin Hakları İle İlgili Diğer Bilgiler  
                <br/><br/>
                Kişisel veri sahibi, 6698 Sayılı Kişisel Verilerin Korunması Kanunu’nun 11.maddesine göre: 
                <br/><br/>
                a) Kişisel veri işlenip işlenmediğini öğrenme,
                <br/>
                b) Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,
                <br/>
                c) Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,
                <br/>
                ç) Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,
                <br/>
                d) Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme,
                <br/>
                e) 7. maddede öngörülen şartlar çerçevesinde kişisel verilerin silinmesini veya yok edilmesini isteme,
                <br/>
                f) (d) ve (e) bentleri uyarınca yapılan işlemlerin, kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,
                <br/>
                g) İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,
                <br/>
                ğ) Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme, haklarına sahiptir.
                <br/>
                6698 Sayılı Kişisel Verilerin Korunması Kanunu’nun 13.maddesine göre yukarıda belirtilen hakları kullanmak amacıyla kişisel veri sahibinin, için kimliğini tespit edici gerekli bilgiler ile aşağıdaki adrese yazılı olarak veya info@perayson.com adresine e-posta yoluyla başvuruda bulunulabilir.
                <br/><br/>
                Başvuru Adresi: Bayar Teknoloji ve İletişim Anonim Şirketi Hoşnudiye Mh. İsmet İnönü 1 cd. No:30/A Tepebaşı/ESKİŞEHİR
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleKvkkClose} size="sm">
                    Kapat
                </Button>
            </Modal.Footer>
        </Modal>
</>
    );
}

export default RegisterPage;
