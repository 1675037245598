import React,{useState,useEffect,useContext} from 'react';
import { Button, Container, Row, Col, Card, Form, InputGroup,Spinner } from 'react-bootstrap';
import SideBar from '../components/SideBar';
import { toast } from 'react-toastify';
import DataContext from '../contexts/DataContext';
import DepartmentsList from '../components/DepartmentsList';
import {Link} from 'react-router-dom';

function Departments() {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [loading , setLoading] = useState(false);
    const {data,setData} = useContext(DataContext);
    const [locationId, setLocationId] = useState('');
    const [locations, setLocations] = useState([]);

    useEffect(() => {
        setLocations(data.locations)
        console.log(data)
    },[]);

    const handleLocationChange = (e) => {
        setLocationId(e.target.value);
    }

    const hSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem("token");
            setLoading(true);
            await fetch('https://s1.digilirapay.com/assistant/company/update', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                    'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify({ newDepartment: {name: name, description: description, location: locationId} })
            })
            .then(response => response.json())
            .then(res => {
                setLoading(false);
                if (res.message) throw new Error(res.message);
                toast.success("Departman başarıyla eklendi!", {
                    theme: "colored",
                    position: toast.POSITION.TOP_CENTER
                });
                setName('');
                setDescription('');
                setLocationId('');
                setLocations(res.locations);
                setData(res);
            })
        } catch (error) {
            setLoading(false);
            toast.error(error.message, {
                theme: "colored",
                position: toast.POSITION.TOP_CENTER
            });
        }
    }
    return(
        <Row className="h-100">
            <Col md={2} className="bg-light h-100 loginCard">
                <SideBar />
            </Col>
            <Col md={10}>
                <Container className="p-3" fluid>
                    <Row>
                        <Col md={6}>
                            <Card className="loginCard">
                                <Card.Body>
                                    <Card.Title className="mb-2">Yeni Departman Ekle</Card.Title>
                                    <div className="subTitle mb-3">İşletmeniz için hemen yeni bir departman ekleyin.</div>

                                    <Form onSubmit={hSubmit}>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text>
                                                <i className="fa fa-circle"></i>
                                            </InputGroup.Text>
                                                <Form.Control
                                                type="text"
                                                value={name}
                                                onChange={e => setName(e.target.value)}
                                                placeholder="Departman Adı Giriniz"
                                                required
                                                />
                                        </InputGroup>

                                        <InputGroup className="mb-3">
                                            <InputGroup.Text>
                                                <i className="fa fa-commenting"></i>
                                            </InputGroup.Text>
                                                <Form.Control
                                                type="text"
                                                value={description}
                                                onChange={e => setDescription(e.target.value)}
                                                placeholder="Açıklama Giriniz"
                                            />
                                        </InputGroup>

                                        <InputGroup className="mb-1">
                                            <InputGroup.Text>
                                            <i className="fa fa-map-marker"></i>
                                            </InputGroup.Text>
                                            <Form.Select aria-label="Şube Seçiniz." name="location" value={locationId} onChange={handleLocationChange}>
                                                <option>Lokasyon Seçiniz</option>
                                                {locations.map((item, index) => (
                                                    <option key={index} value={item._id}>{item.name}</option>
                                                ))}
                                            </Form.Select>
                                        </InputGroup>
                                        <div className="subTitle mb-3">Departmanınızın bağlı olduğu şubeyi seçiniz.</div>
                                        <Button variant="outline-primary" size="sm" className="mb-3" as={Link} to="/branches">Lokasyon Ekle</Button>
                                        
                                        {!loading ? (
                                        <div className="d-grid gap-2 mt-3">
                                            <Button variant="primary" type="submit">
                                                Kaydet
                                            </Button>
                                        </div>
                                        ) : (
                                        <div className="d-grid gap-2 mt-3">
                                            <Button variant="primary" type="submit" disabled>
                                                <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                />
                                                 Kaydediliyor...
                                            </Button>
                                        </div>
                                        )}
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6}>
                            <DepartmentsList data={data}/>
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
    )
}

export default Departments;


