import React,{useState,useEffect,useContext} from 'react';
import { Card, Table, Image,Alert,Badge } from 'react-bootstrap';
import {toast} from 'react-toastify';
import DataContext from '../contexts/DataContext';

function HomeGetScans() {

    const [scans, setScans] = useState([]);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const {data} = useContext(DataContext);

    const getScans = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem("token");

            await fetch('https://s1.digilirapay.com/assistant/company/getscans', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                    'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify({predefined: 'today'})
            })
            .then(response => response.json())
            .then(res => {
                setLoading(false);
                if (res.message) throw new Error(res.message);
                setScans(res.scans);
                setUsers(res.users);
            })
        } catch(error) {
            toast.error(error.message, {
                theme: "colored",
                position: toast.POSITION.TOP_CENTER
            });
            setLoading(false);
        }
    }

    useEffect(() => {
        getScans();
    },[]);

    const getTimeFromDate = (date_) => {
        const date = new Date(date_);
        const time = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        if (date_ !== null && date_ !== undefined) {
            // return 24 hour format
            return time;
        } else {
            return "-";
        }
    }

    const findNameById = (id) => {
        const user = users.find(item => item._id === id);
        return user ? trimIfLong(user.firstName+" "+user.lastName) : "-";
    }

    const findLocationNameById = (id) => {
        const location = data.locations.find(item => item._id === id);
        return location ? trimIfLong(location.name) : "-";
    }

    const trimIfLong = (text) => {
        if (text.length > 14) {
            return text.substring(0, 14) + "...";
        } else {
            return text;
        }
    }

    return(
        <Card className="loginCard mb-3">
            <Card.Body>
                <Card.Title>Bugün yapılan giriş / çıkış işlemleri</Card.Title>
                <div className="subTitle mb-3">Son yapılan giriş / çıkış işlemleri</div>  
                {loading ? (
                    <div className="text-center">
                        <div className="spinner-border text-dark" role="status">
                            <span className="sr-only">Yükleniyor...</span>
                        </div>
                    </div>
                ) : (
                <Table hover striped>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Adı Soyadı</th>
                            <th>Konum</th>
                            <th><i className="fa fa-sign-in"></i> Giriş</th>
                            <th>Çıkış <i className="fa fa-sign-out"></i></th>
                        </tr>
                    </thead>
                    <tbody>
                        {scans.map((item, index) => (
                        <tr key={index}>
                            <td><Image src="https://placehold.co/30x30" rounded /></td>
                            <td>{findNameById(item.user)}</td>
                            <td><Badge bg="light" style={{color: '#222'}}><i className="fa fa-location-arrow"></i> {findLocationNameById(item.location)}</Badge></td>
                            <td>{getTimeFromDate(item.intime)}</td>
                            <td>{item.closed ? getTimeFromDate(item.outtime) : '-'}</td>
                        </tr>   
                        ))}                                               
                    </tbody>
                </Table>
                )}
                {scans.length === 0 && (<Alert variant="light">Bugün hiç giriş / çıkış işlemi yapılmamış.</Alert>)}
            </Card.Body>
        </Card>
    )
}

export default HomeGetScans;