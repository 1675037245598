import React,{useState,useEffect,useContext} from 'react';
import { Button, Container, Row, Col, Card, Form, InputGroup,Spinner } from 'react-bootstrap';
import SideBar from '../components/SideBar';
import { toast } from 'react-toastify';
import { GoogleMap, useJsApiLoader,MarkerF } from '@react-google-maps/api';
import DataContext from '../contexts/DataContext';
import BranchesList from '../components/BranchesList';

const containerStyle = {
    width: '100%',
    height: '400px'
  };

  const center = {
    lat: 39.77390798890012,
    lng: 30.525406621483896
  };

function Branches() {
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [coordinates, setCoordinates] = useState({ lat: center.lat, lng: center.lng });
    const [zoom, setZoom] = useState(8);
    const [loading , setLoading] = useState(false);
    const {data,setData} = useContext(DataContext);

    const [locations, setLocations] = useState([]);

    useEffect(() => {
        setLocations(data.locations);
        setZoom(15);
    }, []);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBlXixvdNzD4RxuGmyppPARiNFTiJ7N0JM"
    })

    const handleAddressChange = (event) => {
        var val = event.target.value;

        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: event.target.value }, (results, status) => {
          if (status === 'OK' && results.length > 0) {
            const { lat, lng } = results[0].geometry.location;

            setCoordinates({ lat: lat(), lng: lng() });
          }
        });
        setAddress(val);
    }

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
      // This is just an example of getting and using the map instance!!! don't just blindly copy!
      const bounds = new window.google.maps.LatLngBounds(center);
      map.fitBounds(bounds);
  
      setMap(map)
    }, [])
  
    const onUnmount = React.useCallback(function callback(map) {
      setMap(null)
    }, [])

    const onMapClick = React.useCallback((event) => {
        setCoordinates({
            lat: event.latLng.lat(),
            lng: event.latLng.lng()
        })
    }, [])

    const getCoordinatesFromBrowser = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setCoordinates({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                })
            });
            toast.success("Konumunuz başarıyla alındı.", {
                position: toast.POSITION.TOP_CENTER
            });
        } else {
            toast.error("Tarayıcınız konum bilgisini desteklemiyor.", {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    const hSubmit = async (event) => {
        event.preventDefault();
        try {
            setLoading(true);
            const token = localStorage.getItem("token");
            //if empty name throw error
            if (name === null) throw new Error("Şube adı zorunludur!");
            //check name is empty
            if (name === "") throw new Error("Şube adı zorunludur!");
            //if empty address throw error
            if (address === null) throw new Error("Adres zorunludur!");
            //check address is empty
            if (address === "") throw new Error("Adres zorunludur!");
            //if empty lat throw error
            if (coordinates.lat === null) throw new Error("Şube konumu zorunludur!");
            //if empty lng throw error
            if (coordinates.lng === null) throw new Error("Şube konumu zorunludur!");

            await fetch('https://s1.digilirapay.com/assistant/company/addlocation', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify({ name: name, address: address,lat:coordinates.lat,long:coordinates.lng})
                })
                .then(response => response.json())
                .then(res => {
                    console.log(res);
                    setLoading(false);
                    if (res.message) { throw new Error(res.message); }
                    setLocations(data)
                    setData({...data,locations:res});
                    toast.success("Şube başarıyla eklendi.", {
                        position: toast.POSITION.TOP_CENTER,
                        theme : "colored"
                    })
                });
        } catch (error) {
            setLoading(false);
            toast.error(error.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    return(
        <Row className="h-100">
            <Col md={2} className="bg-light h-100 loginCard">
                <SideBar />
            </Col>
            <Col md={10}>
                <Container className="p-3" fluid>
                    <Row>
                        <Col md={6}>
                            <Card className="loginCard">
                                <Card.Body>
                                    <Card.Title className="mb-2">Yeni Lokasyon Ekle</Card.Title>
                                    <div className="subTitle mb-3">İşletmeniz için hemen yeni bir lokasyon ekleyin.</div>
                                    <Form onSubmit={hSubmit}>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text>
                                                <i className="fa fa-building-o"></i>
                                            </InputGroup.Text>
                                                <Form.Control
                                                type="text"
                                                value={name}
                                                onChange={e => setName(e.target.value)}
                                                placeholder="Lokasyon Adı Giriniz"
                                                required
                                                />
                                        </InputGroup>
                                        <div className="subTitle">Seçili Koordinatlar: {coordinates.lat +','+coordinates.lng}</div>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text>
                                                <i className="fa fa-location-arrow"></i>
                                            </InputGroup.Text>
                                                <Form.Control
                                                type="text"
                                                value={address}
                                                onChange={handleAddressChange}
                                                placeholder="Adres giriniz."
                                                required
                                                />
                                        </InputGroup>
                                        
                                        {isLoaded ? (
                                            <>
                                            <div className="subTitle">
                                                Harita üzerinde şubenizin tam konumunu konumunu işaretleyebilirsiniz.
                                            </div>
                                            <GoogleMap
                                                mapContainerStyle={containerStyle}
                                                center={coordinates}
                                                defaultZoom = {zoom}
                                                onLoad={onLoad}
                                                onUnmount={onUnmount}
                                                onClick={onMapClick}
                                            >
                                                <MarkerF position={coordinates}/>
                                            </GoogleMap>
                                            <Button className="mt-2" variant="light" size="sm" type="button" onClick={getCoordinatesFromBrowser}>
                                                Bulunduğum konumu al
                                            </Button>
                                            </>
                                        ) : <></>}

                                        {!loading ? (
                                        <div className="d-grid gap-2 mt-3">
                                            <Button variant="primary" type="submit">
                                                Kaydet
                                            </Button>
                                        </div>
                                        ) : (
                                        <div className="d-grid gap-2 mt-3">
                                            <Button variant="primary" type="submit" disabled>
                                                <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                />
                                                 Kaydediliyor...
                                            </Button>
                                        </div>
                                        )}
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6}>
                            <BranchesList data={data}/>
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
    )
}

export default Branches;


