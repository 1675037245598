import React, { useState, useContext} from "react";
import { Card, Table, Button,Spinner,Alert,Badge } from "react-bootstrap";
import { toast } from 'react-toastify';
import DataContext from "../contexts/DataContext";

function DepartmentsList() {

    const [loading, setLoading] = useState(false);
    const { data, setData } = useContext(DataContext);

    const deleteDepartment = async (id) => {
        try {
            // ask are you sure to delete
            if (!window.confirm("Departman silmek istediğinize emin misiniz?")) { return; }
            setLoading(true);
            const token = localStorage.getItem("token");
            await fetch('https://s1.digilirapay.com/assistant/company/removedepartment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify({ department: id })
            })
                .then(response => response.json())
                .then(res => {
                    setLoading(false);
                    if (res.message) { throw new Error(res.message); }
                    setData({...data,department:res});
                    toast.success("Departman başarıyla silindi.", {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored"
                    })
                });
        } catch (error) {
            setLoading(false);
            toast.error(error.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    const locationName = (id) => {
        const list = data.locations;
        const location = list.find(item => item._id === id);
        return location ? location.name : "-";
    }

    return(
        <Card className="loginCard">
        <Card.Body>
            <Card.Title className="mb-2">Departmanlar</Card.Title>
            <div className="subTitle mb-3">Aktif departmanlarınız.</div>
            {Array.isArray(data.department) && data.department.length ? (
            <Table hover striped size="sm">
                <thead>
                    <tr>
                        <th>Departman</th>
                        <th>Lokasyon</th>
                        <th>Açıklama</th>
                        <th>İşlemler</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(data.department) && data.department.length && data.department.map((department, index) => (
                        <tr key={index}>
                            <td>{department.name}</td>
                            <td><Badge bg="secondary">{locationName(department.location)}</Badge></td>
                            <td>
                                <div className="subTitle">{department.description}</div>
                            </td>
                            <td>
                                {loading ? <Spinner animation="border" size="sm" /> : (
                                <Button variant="outline-danger" size="sm" onClick={() => deleteDepartment(department._id)}>
                                    <i className="fa fa-trash"></i>
                                </Button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            ) : (<Alert variant="light" className="text-center"><i className="fa fa-exclamation-triangle"></i> Departman bulunamadı</Alert>)}
        </Card.Body>
    </Card>
    )
}

export default DepartmentsList;