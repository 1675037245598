import React,{useState,useEffect,useContext} from "react";
import { Card,Row,Col,Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import DataContext from '../contexts/DataContext';

function BranchesBox() {
    const history = useHistory();
    const [branchCount, setBranchCount] = useState(0);
    const {data} = useContext(DataContext);

    useEffect(() => {
        setBranchCount(data.locations.length);
    }, []);

    return (
        <>
            <Card className="loginCard mb-3">
                <Card.Body>
                    <Card.Title className="mb-4">Lokasyonlar</Card.Title>
                    <Row>
                        <Col xs={6}>
                            <div className="cardNumber">
                                {branchCount}
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="d-grid gap-2">
                                <Button variant="primary" size="sm" onClick={() => history.push('/branches')}>
                                    Şube Ekle
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    )
}

export default BranchesBox;