import React, { useState, useContext} from "react";
import { Card, Table, Button,Spinner,Alert,Modal } from "react-bootstrap";
import { toast } from 'react-toastify';
import DataContext from "../contexts/DataContext";
import QRCode from "react-qr-code";
import { saveSvgAsPng } from 'save-svg-as-png';


function BranchesList() {

    const [loading, setLoading] = useState(false);
    const { data, setData } = useContext(DataContext);
    const [show, setShow] = useState(false);
    const [qr, setQR] = useState(null);

    const deleteBranch = async (id) => {
        try {
            // ask are you sure to delete
            if (!window.confirm("Şubeyi silmek istediğinize emin misiniz?")) { return; }
            setLoading(true);
            const token = localStorage.getItem("token");
            await fetch('https://s1.digilirapay.com/assistant/company/removelocation', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify({ location: id })
            })
                .then(response => response.json())
                .then(res => {
                    setLoading(false);
                    if (res.message) { throw new Error(res.message); }
                    setData({...data,locations:res});
                    toast.success("Şube başarıyla silindi.", {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored"
                    });
                });
        } catch (error) {
            setLoading(false);
            toast.error(error.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    const createQR = async (id) => {
        setShow(true);
        setQR(id);
    }

    const handleDownload = (name) => {
        const modalBody = document.querySelector('.modal-body');
        const svgElement = modalBody.querySelector('svg');
      
        if (svgElement) {
          saveSvgAsPng(svgElement, name+'.png');
        } else {
          console.error('SVG element not found');
        }
      };

    return(
        <>
        <Card className="loginCard">
        <Card.Body>
            <Card.Title className="mb-2">Lokasyonlar</Card.Title>
            <div className="subTitle mb-3">Aktif lokasyonlarınız.</div>
            {Array.isArray(data.locations) && data.locations.length ? (
            <Table hover striped size="sm">
                <thead>
                    <tr>
                        <th>Lokasyon Adı</th>
                        <th>Adres</th>
                        <th>İşlemler</th>
                    </tr>
                </thead>
                <tbody>
                    {data.locations.map((branch, index) => (
                        <tr key={index}>
                            <td>{branch.name}</td>
                            <td>
                                {branch.address}
                            </td>
                            <td>
                                {loading ? <Spinner animation="border" size="sm" /> : (
                                <>
                                <Button variant="outline-danger" size="sm" onClick={() => deleteBranch(branch._id)}>
                                    <i className="fa fa-trash"></i>
                                </Button>
                                {' '}
                                <Button variant="outline-dark" size="sm" onClick={() => createQR(branch._id)}><i className="fa fa-qrcode"></i></Button>
                                </>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            ) : (<Alert variant="light" className="text-center"><i className="fa fa-exclamation-triangle"></i> Şube bulunamadı</Alert>)}
        </Card.Body>
    </Card>
    <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Body>
            <QRCode
            size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={qr}
                viewBox={`0 0 256 256`}
                className="mb-2"
            />
            <Button variant="outline-dark" size="sm" onClick={() => handleDownload(qr)}><i className="fa fa-download"></i> Download</Button>
        </Modal.Body>
    </Modal>
    </>
    )
}

export default BranchesList;