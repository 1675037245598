import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import DataContext from '../contexts/DataContext';
function DepartmensBox() {
    const history = useHistory();
    const [departmentCount, setDepartmentCount] = useState(0);
    const {data} = useContext(DataContext);

    useEffect(() => {
        setDepartmentCount(data.department.length);
    }, []);

    return (
        <Card className="loginCard mb-3">
            <Card.Body>
                <Card.Title className="mb-4">Departman Sayısı</Card.Title>
                <Row>
                    <Col xs={6}>
                        <div className="cardNumber">
                            {departmentCount}
                        </div>
                    </Col>
                    <Col xs={6}>
                        <div className="d-grid gap-2">
                            <Button variant="primary" size="sm" onClick={() => history.push('/departments')}>
                                Ekle
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default DepartmensBox;