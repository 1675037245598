import React,{useState,useEffect} from 'react';
import { Row,Col,Form,Button,InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Link,useHistory } from "react-router-dom";
import PasswordStrengthBar from 'react-password-strength-bar';
import Cities from '../components/Cities';

function RegisterPage() {
    const history = useHistory();

    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [taxNo, setTaxNo] = useState('');
    const [taxOffice, setTaxOffice] = useState('');
    const [address, setAddress] = useState('');
    const [province, setProvince] = useState('');
    const [city, setCity] = useState('');

    useEffect(() => {
        const getEmail = localStorage.getItem("app_email");
        if (getEmail) {
            setEmail(getEmail);
        } else {
            setEmail(null);
            toast.error("Lütfen e-mail adresinizi giriniz.", {
                theme: "colored",
                position: toast.POSITION.TOP_CENTER
            });
            history.push('/register');
        }
    }, []);

    async function handleRegister() { 
        try {
            console.log(password)
            await fetch('https://s1.digilirapay.com/assistant/company/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username: email,
                    password: password,
                    companyname: companyName,
                    taxNo: taxNo,
                    taxOffice: taxOffice,
                    address: address,
                    city: province,
                    town: city,
                })
            })
            .then(res => res.json())
            .then(data => {
                //check message key is exist
                if (data.message) throw new Error(data.message);
                
                toast.success("Kayıt işlemi başarılı! Oturum açabilirsiniz.", {
                    theme: "colored",
                    position: toast.POSITION.TOP_CENTER
                });
                //delete from localstorage
                localStorage.removeItem("app_email");
                history.push('/login');
            }
            );
        } catch (error) {
            toast.error(error.message, {
                theme: "colored",
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    function handlePasswordChange (event) {
        setPassword(event.target.value);
    }

    function handleNameChange (event) {
        setName(event.target.value);
    }

    function handleSurnameChange (event) {
        setSurname(event.target.value);
    }

    function handleCompanyNameChange (event) {
        setCompanyName(event.target.value);
    }

    function handleTaxNoChange (event) {
        setTaxNo(event.target.value);
    }

    function handleTaxOfficeChange (event) {
        setTaxOffice(event.target.value);
    }

    function handleAddressChange (event) {
        setAddress(event.target.value);
    }

    function handleProvinceChange (event) {
        setProvince(event.target.value);
    }

    function handleCityChange (event) {
        setCity(event.target.value);
    }

    function handlePassword2Change (event) {
        setPassword2(event.target.value);
    }

    return (
        <Row className="h-100">
            <Col md={5}>
                <div className="registerCard">
                    <div>
                        <div className="loginCardTitle mt-2">
                            Hemen Kayıt Olun!
                        </div>
                        <div className="loginCardSubTitle mb-2">
                            Lütfen yöneticisi olacağınız şirket bilgilerini giriniz.
                        </div>
                        <Form className="mb-3 p-5">
                            <Row>
                                <Col xs={12} md={6}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text>
                                        <i className="fa fa-user-circle-o"></i>
                                        </InputGroup.Text>
                                        <Form.Control type="text" name="name" value={name} placeholder="Adınız" onChange={handleNameChange}/>
                                    </InputGroup>
                                </Col>
                                <Col xs={12} md={6}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text>
                                        <i className="fa fa-user-circle-o"></i>
                                        </InputGroup.Text>
                                        <Form.Control type="text" name="surname" value={surname} placeholder="Soyadınız" onChange={handleSurnameChange}/>
                                    </InputGroup>
                                </Col>
                                <Col xs={12}>
                                    <Form.Control type="text" name="companyName" className="mb-3" value={companyName} onChange={handleCompanyNameChange} placeholder="Firma Ünvanı"/>
                                </Col>
                                <Col xs={12} md={6}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text>
                                        <i className="fa fa-id-card-o"></i>
                                        </InputGroup.Text>
                                        <Form.Control type="text" name="taxNo" placeholder="Vergi Numarası" value={taxNo} onChange={handleTaxNoChange}/>
                                    </InputGroup>
                                </Col>
                                <Col xs={12} md={6}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text>
                                        <i className="fa fa-map-marker"></i>
                                        </InputGroup.Text>
                                        <Form.Control type="text" name="taxOffice" placeholder="Vergi Dairesi" value={taxOffice} onChange={handleTaxOfficeChange}/>
                                    </InputGroup>
                                </Col>
                                <Col xs={12}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text>
                                        <i className="fa fa-location-arrow"></i>
                                        </InputGroup.Text>
                                        <Form.Control type="text" name="address" placeholder="Adres" value={address} onChange={handleAddressChange}/>
                                    </InputGroup>
                                </Col>
                                <Col xs={12} md={6}>
                                    <InputGroup className="mb-4">
                                        <InputGroup.Text>
                                        <i className="fa fa-map-marker"></i>
                                        </InputGroup.Text>
                                        <Form.Select aria-label="Şehir Seçiniz" name="province" value={province} onChange={handleProvinceChange}>
                                            <option>Şehir Seçiniz</option>
                                            {Cities.map((item, index) => (
                                                <option key={index} value={item.plateCode}>{item.name}</option>
                                            ))}
                                        </Form.Select>
                                    </InputGroup>
                                </Col>
                                <Col xs={12} md={6}>
                                    <InputGroup className="mb-4">
                                        <InputGroup.Text>
                                        <i className="fa fa-map-marker"></i>
                                        </InputGroup.Text>
                                        <Form.Control type="text" name="city" placeholder="İlçe giriniz" value={city} onChange={handleCityChange}/>
                                    </InputGroup>
                                </Col>
                                <Col xs={12}>
                                    <div className="passwordTitle">Şifre İşlemleri</div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <InputGroup className="mt-2">
                                        <InputGroup.Text>
                                        <i className="fa fa-lock"></i>
                                        </InputGroup.Text>
                                        <Form.Control type="password" name="app_password" placeholder="Şifrenizi giriniz." value={password} onChange={handlePasswordChange}/>
                                    </InputGroup>
                                    <div style={{display: 'block'}} className="mt-1">
                                        <PasswordStrengthBar password={password} shortScoreWord="çok kısa" scoreWords={["çok zayıf","zayıf","orta","güçlü","çok güçlü"]} />
                                    </div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <InputGroup className="mt-2">
                                        <InputGroup.Text>
                                        <i className="fa fa-lock"></i>
                                        </InputGroup.Text>
                                        <Form.Control type="password" name="app_password2" placeholder="Şifrenizi giriniz. (tekrar)" value={password2} onChange={handlePassword2Change}/>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <div className="d-grid gap-2 mt-4">
                                <Button variant="primary" onClick={handleRegister}>
                                    Kaydı Başlat
                                </Button>
                                <Button variant="light" as={Link} to="/login">
                                    Giriş Yap
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </Col>
            <Col md={7}>
            </Col>
        </Row>
    );
}

export default RegisterPage;
