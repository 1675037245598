import React,{useContext} from 'react';
import { Nav } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import UserContext from '../contexts/UserContext';

function SideBar() {

    const history = useHistory();
    const { setIsLogged } = useContext(UserContext);

    function handleLogout() {
        setIsLogged(false);
        localStorage.clear();
        history.push('/login');
    }

    return (
        <Nav defaultActiveKey="/dashboard" className="flex-column sideMenu">
            <Nav.Link onClick={() => history.push('/dashboard')}><i className="fa fa-dashboard mr-1"></i> Kontrol Paneli</Nav.Link>
            <Nav.Link onClick={() => history.push('/employees')}>Çalışanlar</Nav.Link>
            <Nav.Link onClick={() => history.push('/branches')}>Lokasyonlar</Nav.Link>
            <Nav.Link onClick={() => history.push('/departments')}>Departmanlar</Nav.Link>
            <Nav.Link onClick={handleLogout}>Oturumu Kapat</Nav.Link>
        </Nav>
    )
}

export default SideBar;