import React from "react";
import { Navbar,Container,Nav,NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";


function Header() {
    return (
      <Navbar variant="dark" expand="lg">
        <Container fluid>
          <Navbar.Brand as={Link} to="/dashboard">Perasyon Yönetim</Navbar.Brand>
        </Container>
      </Navbar>
    );
  }

export default Header;