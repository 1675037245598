import React, { useState, useEffect, useContext } from 'react';
import {Button, Row, Container, Col,Card,Image,Form,InputGroup,Spinner,Table,Badge,Alert} from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import DataContext from '../contexts/DataContext';
import { toast } from 'react-toastify';
import SideBar from '../components/SideBar';
import EmployeesList from '../components/EmployeesList';

function Employees() {
    const history = useHistory();
    const {data,setData} = useContext(DataContext);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [userName, setUserName] = useState('');
    const [tcNo, setTcNo] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [tel, setTel] = useState('');
    const [locationId, setLocationId] = useState('');
    const [position, setPosition] = useState('');
    const [departmentId, setDepartmentId] = useState('');

    const [loading, setLoading] = useState(false);
    
    const hSubmit = async (e) => {
        e.preventDefault();
        try {
            if (password !== password2) throw new Error("Şifreler uyuşmuyor!");
            if (firstName === null || firstName === '') throw new Error("Lütfen personel adını giriniz!");
            if (lastName === null || lastName === '') throw new Error("Lütfen personel soyadını giriniz!");
            if (userName === null || userName === '') throw new Error("Lütfen kullanıcı adını giriniz!");
            if (tcNo === null || tcNo === '') throw new Error("Lütfen TC kimlik numarasını giriniz!");
            if (password === null || password === '') throw new Error("Lütfen şifreyi giriniz!");
            if (password2 === null || password2 === '') throw new Error("Lütfen şifreyi tekrar giriniz!");
            if (tel === null || tel === '') throw new Error("Lütfen telefon numarasını giriniz!");
            if (locationId === null || locationId === '') throw new Error("Lütfen lokasyon seçiniz!");
            if (position === null || position === '') throw new Error("Lütfen ünvan giriniz!");

            const token = localStorage.getItem("token");
            setLoading(true);
            await fetch('https://s1.digilirapay.com/assistant/users/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                    'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify({firstName: firstName, lastName: lastName, username: userName, tcno: tcNo, password: password, tel: tel, office: locationId, position: position,department:departmentId,company: data._id})
            })
            .then(response => response.json())
            .then(res => {
                if (res.message) throw new Error(res.message);
                toast.success("Personel başarıyla eklendi!", {
                    theme: "colored",
                    position: toast.POSITION.TOP_CENTER
                });
                setFirstName('');
                setLastName('');
                setUserName('');
                setTcNo('');
                setPassword('');
                setPassword2('');
                setTel('');
                setLocationId('');
                setPosition('');
                setDepartmentId('');
                setLoading(false);
            })
        } catch (error) {
            setLoading(false);
            toast.error(error.message, {
                theme: "colored",
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    const handleTcNo = (e) => {
        const re = /^[0-9\b]+$/;    
        if (e.target.value === '' || re.test(e.target.value)) {
            setTcNo(e.target.value)
        }
    }

    const handleLocationChange = (e) => {
        setLocationId(e.target.value);
    }

    const handleDepartmentChange = (e) => {
        setDepartmentId(e.target.value);
    }

    const departmentsInLocation = (id) => {
        const departments = data.department?.filter(item => item.location === locationId);
        return departments;
    }

    return(
        <Row className="h-100">
            <Col md={2} className="bg-light h-100 loginCard">
                <SideBar />
            </Col>
            <Col md={10}>
                <Container className="p-3" fluid>
                    <Row>
                        <Col md={4}>
                            <Card className="loginCard">
                                <Card.Body>
                                    <Card.Title className="mb-2">Yeni Personel Ekle</Card.Title>
                                    <div className="subTitle mb-3">Yeni personel kaydı oluşturun.</div>
                                    <div className="employeeAvatar mb-3" style={{display: 'none'}}>
                                        <div className="edit" onClick={() => alert("Avatar Upload Modal")}>
                                            <i className="fa fa-pencil"></i>
                                        </div>
                                        <Image src="https://placehold.co/100x100" rounded />
                                    </div>
                                    <Form onSubmit={hSubmit}>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text>
                                                <i className="fa fa-user"></i>
                                            </InputGroup.Text>
                                                <Form.Control type="text" value={firstName} onChange={e => setFirstName(e.target.value)} placeholder="Personel Adı" required />
                                        </InputGroup>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text>
                                                <i className="fa fa-user"></i>
                                            </InputGroup.Text>
                                                <Form.Control type="text" value={lastName} onChange={e => setLastName(e.target.value)} placeholder="Personel Soyadı" required />
                                        </InputGroup>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text>
                                                <i className="fa fa-info-circle"></i>
                                            </InputGroup.Text>
                                                <Form.Control type="text" value={position} onChange={e => setPosition(e.target.value)} placeholder="Ünvan (Örnek: Müdür, Direktör vs)" />
                                        </InputGroup>
                                        <InputGroup className="mb-1">
                                            <InputGroup.Text>
                                                <i className="fa fa-user-plus"></i>
                                            </InputGroup.Text>
                                                <Form.Control type="text" value={userName} onChange={e => setUserName(e.target.value)} placeholder="Kullanıcı adı giriniz." required />
                                        </InputGroup>
                                        <div className="subTitle mb-3">Personel bu kullanıcı adını oturum açarken kullanacaktır.</div>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text>
                                                <i className="fa fa-phone"></i>
                                            </InputGroup.Text>
                                                <Form.Control type="text" value={tel} onChange={e => setTel(e.target.value)} placeholder="Telefon Numarası" />
                                        </InputGroup>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text>
                                                <i className="fa fa-id-card"></i>
                                            </InputGroup.Text>
                                                <Form.Control type="text" value={tcNo} onChange={handleTcNo} placeholder="TC Kimlik Numarası" />
                                        </InputGroup>

                                        
                                        {data.locations &&
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text>
                                                <i className="fa fa-location-arrow"></i>
                                            </InputGroup.Text>
                                            <Form.Select aria-label="Lokasyon Seçiniz" value={locationId} onChange={handleLocationChange}>
                                                <option>Lokasyon Seçiniz</option>
                                                {data.locations?.map((item, index) => (
                                                <option value={item._id} key={index}>{item.name}</option>
                                                ))}
                                            </Form.Select>
                                        </InputGroup>
                                        }
                                        {data.locations && (
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text>
                                                <i className="fa fa-id-card"></i>
                                            </InputGroup.Text>
                                            <Form.Select aria-label="Departman Seçiniz" value={departmentId} onChange={handleDepartmentChange} disabled={departmentsInLocation(locationId).length === 0 ? true : false}>
                                                <option value="">Departmansız</option>
                                                {departmentsInLocation(locationId).map((item, index) => (
                                                <option value={item._id} key={index}>{item.name}</option>
                                                ))}
                                            </Form.Select>
                                        </InputGroup>
                                        )}
                                        {data.department && (
                                            <Alert variant="light" className="mb-1">
                                                <p>
                                                    Bu lokasyon için hemen yeni bir departman ekleyebilirsiniz.
                                                </p>
                                                <hr />
                                                <div className="d-flex justify-content-start">
                                                    <Button onClick={() => history.push('/departments')} variant="primary" size="sm">
                                                        Departman Ekle
                                                    </Button>
                                                </div>
                                            </Alert>
                                        )}
                                        <div className="bg-light p-3 rounded">
                                            <div className="subTitle mb-3">Şifre İşlemleri</div>
                                            <Alert variant="warning" className="mb-3">
                                                <i className="fa fa-exclamation"></i> Personel oturum açarken bu şifreyi kullanacaktır.
                                            </Alert>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text>
                                                    <i className="fa fa-lock"></i>
                                                </InputGroup.Text>
                                                    <Form.Control type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Şifre" required />
                                            </InputGroup>
                                            <InputGroup className="mb-0">
                                                <InputGroup.Text>
                                                    <i className="fa fa-lock"></i>
                                                </InputGroup.Text>
                                                    <Form.Control type="password" value={password2} onChange={e => setPassword2(e.target.value)} placeholder="Şifre Tekrar" required />
                                            </InputGroup>
                                        </div>
                                        {!loading ? (
                                        <div className="d-grid gap-2 mt-3">
                                            <Button variant="primary" type="submit">
                                                Kaydet
                                            </Button>
                                        </div>
                                        ) : (
                                        <div className="d-grid gap-2 mt-3">
                                            <Button variant="primary" type="submit" disabled>
                                                <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                />
                                                 Kaydediliyor...
                                            </Button>
                                        </div>
                                        )} 
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={8}>
                            <EmployeesList loading={loading}/>
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
    )
}

export default Employees;